import React from 'react';
import { PathService } from '../../services';
import { OverviewListViewContext, TemplateProps } from '../../types';
import { OverviewListView } from '../../views/overview';

const OverviewList: React.FunctionComponent<
  TemplateProps<OverviewListViewContext>
> = ({ location, pageContext }) => {
  PathService.set(location.pathname);
  return <OverviewListView list={JSON.parse(pageContext.list)} />;
};

export default OverviewList;
